<template>
  <div>
    <div class="search" @click="search">
      <u-search placeholder="搜索商品" disabled="false"></u-search>
    </div>
    <div class="goodsbox" v-if="goodsList.length">
      <div
        class="goodslis"
        v-for="(item, index) in goodsList"
        :key="index"
        @click="jumpClick(item.id)"
      >
        <div>
          <img :src="item.logo" mode="" class="img"/>
        </div>
        <div class="goodstext">
          <div>
            <text>{{item.title}}</text>
          </div>
          <div class="addres">
            <div style="display: flex">
                <el-rate v-model="item.xj" disabled size="large"/>
              <!-- <u-rate
                :count="item.score.slice(0, 1)"
                v-model="value"
                readonly
                size="14"
              ></u-rate> -->
              <text class="juli">{{ item.score }}</text>
            </div>
          </div>
          <div class="quanlieb">
            <div class="quanlieb_item">
              <div class="item1">赠</div>
              <div class="item2">
                <div class="item2_one">{{ item.sub_rebate }}%补贴值</div>
                <div class="item2_two">{{ item.self_rebate }}%贡献值</div>
              </div>
              <div class="item3">
                {{
                  parseFloat(item.sub_rebate) + parseFloat(item.self_rebate)
                }}%
              </div>
            </div>
            <!-- <div style="font-size: 22rem;">人气：<text style="color: red;">91</text></div> -->
            <!-- <div class="distance">距您{{ item.distance }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-else style="margin-top: 100rem">
      <u-empty mode="order" text="暂无列表"></u-empty>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import axios from '@/request/index'
import { useRouter } from 'vue-router'
export default {
  name: 'nearbyIndex',
  setup () {
    const store = useRouter()
    // 获取数据
    const goodsList = reactive([])
    axios({
      url: 'mall_xcx/oto/shops-list',
      method: 'post',
      data: { sort: 1 }
    }).then((res) => {
      res.data.data.shopList.forEach((element) => {
        element.xj = element.score.slice(0, 1)
        goodsList.push(element)
      })
    })
    // 获取金纬度
    // 去店铺详情
    const jumpClick = (v) => {
      store.push({ path: 'shopDetails', query: { goodsId: v } })
    }
    return { goodsList, jumpClick }
  }
}
</script>

<style lang="less" scoped>
.search {
  padding: 2rem 2rem 0;
}
.quanlieb_item {
  // margin-top: 10rem;
  // width: 210rem;
  // height: 56rem;
  margin-top: 0.2rem;
  border-radius: 0.6rem 0 0.3rem 0;
  display: flex;
  border: 0.1rem solid rgba(255, 215, 000, 1);

  // overflow: hidden;
  .item1 {
    border-radius: 0.3rem 0 0 0;
    line-height: 1.3rem;
    padding: 0 0.2rem;
    color: red;
    font-size: 0.7rem;
    text-align: center;
    background-color: rgba(255, 215, 000, 1);
  }

  .item2 {
    font-size: 0.5rem;
    // border-left: 2rem solid;
    // border-right: 2rem solid;
    text-align: center;
    line-height: 1;

    .item2_one {
      padding: 0 0.6rem;
      border-bottom: 0.1rem solid rgba(255, 215, 000, 1);
    }
  }

  .item3 {
    border-radius: 0 0 0.3rem 0;
    background-color: rgba(255, 215, 000, 1);
    font-size: 0.6rem;
    // line-height: 1rem;
    text-align: center;
    padding: 0 0.1rem;
    color: red;
    line-height: 1.3rem;
  }
}

.goodsbox {
  margin: 0.4rem auto;
  padding: 0 0.9;
  box-sizing: border-box;
  width: 80%;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  // margin-right: 0.1rem;
  .goodslis {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 0.4rem;
    box-sizing: border-box;
    border-radius: 0.6rem;
    margin-bottom: 0.4rem;
    width: 33%;
    // width: 48%;
    .img {
      width: 6rem;
      height: 6rem;
    }

    .goodstext {
      margin-left: 0.3rem;
      flex: 1;
      // line-height: 2;
    }

    .addres {
      display: flex;
      justify-content: space-between;
    }

    .juli {
      font-size: 0.7rem;
      color: red;
      margin-left: 1rem;
      line-height: 2rem;
    }

    .quanlieb {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }

    .quan {
      font-size: 0.6rem;
      padding: 0.3rem 0.2rem;
      background-color: red;
      color: #ffffff;
      border-radius: 1rem;
    }
  }
}
</style>
