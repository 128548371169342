<template>
  <div class="bosss">
    <div class="tp">
      <img
        class="img"
        :src="imgs"
        alt=""
      />
    </div>
    <div>
      <div class="price">
      ￥{{ price }}
    </div>
    <div class="content">
      {{ content || goodsName}}
    </div>
    </div>
  </div>
</template>

<script>
// import { reactive } from "vue";
// import axios from "@/request/index";
export default {
  name: 'CommodiTy',
  props: {
    content: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    imgs: {
      type: String,
      required: true
    },
    goodsName: {
      type: String,
      required: true
    }
  },
  setup () {}
}
</script>

<style lang='less' scoped>
.bosss {
  // width: 30rem;
  font-size: 1rem;
  display: flex;
  background: #F7F9FA;
  padding: 0.6rem 0.6rem;
  border-radius: 0.6rem;
  .tp {
    width: 4rem;
    // height: 20rem;
    // overflow: hidden;
    margin-right: 2rem;
    .img {
      width: 5rem;
      height: 5rem;
    }
  }
  .price {
    color: red;
    margin-bottom: 1rem;
  }
  .content {
    // width: 50%;
    width: 8rem;
    font-size: 0.7rem;
    -webkit-line-clamp: 2; /*用来限制在一个块元素显示的文本的行数*/
    display: -webkit-box; /*必须结合的属性，将对象作为弹性伸缩盒子模型显示*/
    -webkit-box-orient: vertical; /*必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式*/
    overflow: hidden;
    word-break: break-all;
    color: #666;
    line-height: 1.5;
  }
}
</style>
