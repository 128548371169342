<template>
  <div class="boss">
    <div class="userbox">
      <img :src="data.logo" class="img" />
      <div style="margin-top: 0.24rem">{{ data.title }}</div>
    </div>
    <div style="padding: 0 0.24rem" v-html="data.image"></div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
  name: 'inforMation',
  setup () {
    const store = useRoute()
    console.log(JSON.parse(store.query.obj))
    const data = JSON.parse(store.query.obj)
    data.image = data.image.replaceAll(
      '<img',
      '<img style="max-width:100%;height:auto;display:block;margin:0 auto;"'
    )
    return { data }
  }
}
</script>

<style lang="less" scoped>
.boss {
  padding: 0 25%;
}
.userbox {
  padding: 0.5rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .img {
    width: 8rem;
    height: 8rem;
    display: block;
    border-radius: 50%;
  }
}
</style>
