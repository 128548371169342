<template>
  <div class="boss" style="padding: 0 24px">
    <div class="content">
      <div class="addresslistbox" v-for="item in addresslists" :key="item.id">
        <div>
          <div class="listtop">
            <div class="moren" v-if="item.isdefault == 1">默认</div>
            <div>
              {{ item.username }}
            </div>
            <div>
              {{ item.mobile }}
            </div>
          </div>
          <div>
            <div>
              {{ item.province }}{{ item.city }}{{ item.district
              }}{{ item.address }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <button @click="addressdeilt(item.id)">修改</button>
          </div>
          <div style="margin-top: 20px">
            <button @click="addressdelete(item.id)">删除</button>
          </div>
        </div>
      </div>
      <div class="but" @click="goAddress">添加收货地址</div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import axios from '@/request/index'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
export default {
  name: 'addressLists',
  setup () {
    const store = useRouter()
    // 地址列表
    const addresslists = reactive([])
    const dataAdd = () => {
      addresslists.length = 0
      const params = new URLSearchParams()
      params.append('token', window.localStorage.getItem('token'))
      axios({
        method: 'post',
        url: 'mall_xcx/users/address-list',
        data: params
      }).then((res) => {
        res.data.data.list.forEach((element) => {
          addresslists.push(element)
        })
      })
    }
    dataAdd()
    // 修改
    const addressdeilt = (v) => {
      store.push({ path: 'address', query: { id: v } })
    }
    // 删除
    const addressdelete = (v) => {
      const params1 = new URLSearchParams()
      params1.append('token', window.localStorage.getItem('token'))
      params1.append('id', v)
      axios({
        method: 'post',
        url: 'mall_xcx/users/address-delete',
        data: params1
      }).then((res) => {
        if (res.data.code !== 0) {
          ElMessage(res.data.msg)
        } else {
          dataAdd()
          ElMessage(res.data.msg)
        }
      })
    }
    // 去添加地址
    const goAddress = () => {
      store.push({ path: 'address' })
    }
    return { addresslists, addressdeilt, addressdelete, goAddress }
  }
}
</script>

<style scoped lang="less">
.boss {
  background: #eee;
  min-height: 100vh;
}
.content {
  background: white;
  width: 50%;
  margin: 0 auto;
  min-height: 100vh;
  .but {
    display: block;
    width: 90%;
    margin: 20px auto;
    background: skyblue;
    line-height: 50px;
    text-align: center;
    color: white;
    border: none;
  }
}
.addresslistbox {
  padding: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  .listtop {
    display: flex;
    // align-items: center;
    .moren {
      text-align: center;
      background-color: #0064ff;
      color: #ffffff;
      border-radius: 8px;
      font-size: 12px;
      padding: 6px;
      margin: -4px 6px 0 0;
    }
  }
}
.dz {
  position: sticky;
  bottom: 0;
}
</style>
