<template>
  <div class="boss">
    <div class="boss_content">
      <div class="shipToAddress">
        <div class="title">收货地址</div>
        <div class="content">
          <div
            :class="value == val ? 'item items' : 'item'"
            v-for="(item, value) in address"
            :key="item.id"
            @click="valClick(value)"
          >
            <div class="top">
              {{ item.province }}{{ item.city }} ({{ item.username }}收)
            </div>
            <div class="bottom">
              {{ item.district }}{{ item.address }} {{ item.mobile }}
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="left" @click="goAddress">使用新地址</div>
          <div class="right" @click="goAddressDisplay">管理收货地址</div>
        </div>
      </div>
      <div class="information">
        <div class="title">确认订单信息</div>
        <div class="content">
          <div class="top">
            <div>店铺宝贝</div>
            <div>商品属性</div>
            <div>单价</div>
            <div>数量</div>
            <div>优惠方式</div>
            <div>小计</div>
          </div>
        </div>
        <div class="bottom">
          <div class="content" v-for="item in data" :key="item.id">
            <div>
              <div>
                <img :src="item.thumb" alt="" />
              </div>
              <div>
                {{ item.goodsName }}
              </div>
            </div>
            <div>2.4-2.7kg/箱</div>
            <div>{{ item.spe_price }}</div>
            <div class="number">
              <div class="number_right">
                <!-- <div
                  :class="num1 > 1 ? 'reduce' : 'reduce redAdd'"
                  @click="reduce"
                >
                  -
                </div> -->
                <div class="num">{{ item.goodsNum || num1 }}</div>
                <!-- <div class="add" @click="add">+</div> -->
              </div>
            </div>
            <div>无</div>
            <div>￥{{ parseFloat(item.spe_price) * parseFloat(item.goodsNum || num1) }}</div>
          </div>
          <div class="address">
            <div>配送方式：包邮</div>
          </div>
        </div>
      </div>
      <div class="confirmOrder">
        <div class="price">
          实付款￥<text style="color: rgb(255, 0, 54); font: 700 0.3rem tahoma">{{
            zj || data[0].spe_price
          }}</text>
        </div>
        <div class="address">
          寄送至：<text
            style="color: color: #333;; font-size: 0.1rem;margin:8rem 0"
            >{{ address[val].province }} {{ address[val].city }}
            {{ address[val].district }}{{ address[val].address }}</text
          >
        </div>
        <div class="name">
          收货人：{{ address[val].username }} {{ address[val].mobile }}
        </div>
        <div class="ord">提交订单</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/request/index'
import { reactive, h, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
export default {
  name: 'confirmOrder',
  setup () {
    const route = useRoute()
    const store = useRouter()
    // 获取订单信息
    const data = reactive([])
    const zj = ref(null)
    console.log(route.query.flag)
    if (route.query.name === 'gwc') {
      console.log(route.query)
      zj.value = route.query.total
      JSON.parse(route.query.obj).ss.forEach((res1) => {
        const sa = {}
        const param = new URLSearchParams()
        param.append('goodsId', res1.goodsId)
        axios({
          method: 'post',
          url: 'mall_xcx/index/goods-detail',
          data: param
        }).then((res) => {
          const resData = res.data.data.detail
          for (const item in resData) {
            sa[item] = resData[item]
          }
          sa.goodsNum = res1.goodsNum
          data.push(sa)
        })
      })
    } else {
      const param = new URLSearchParams()
      param.append('goodsId', route.query.goodsId)
      const obj = {}
      if (route.query.flag === 'ly') {
        console.log(11111111)
        axios({
          method: 'post',
          url: 'gou/index/goods-detail',
          data: param
        }).then((res) => {
          const resData = res.data.data.detail
          for (const item in resData) {
            obj[item] = resData[item]
          }
          data.push(obj)
        })
      } else {
        axios({
          method: 'post',
          url: 'mall_xcx/index/goods-detail',
          data: param
        }).then((res) => {
          const resData = res.data.data.detail
          for (const item in resData) {
            obj[item] = resData[item]
          }
          data.push(obj)
        })
      }
    }
    // 定义购买产品数量
    const num1 = ref(1)
    if (route.query.number) {
      num1.value = route.query.number
    }
    // 减少商品数量
    const reduce = () => {
      if (num1.value > 1) {
        num1.value = num1.value - 1
      } else {
        ElMessage({
          message: h('p', null, [h('span', null, '数量低于范围')])
        })
      }
    }
    // 增加产品数量
    const add = () => {
      num1.value = num1.value + 1
    }
    // 地址
    const address = reactive([])
    const params = new URLSearchParams()
    params.append('token', window.localStorage.getItem('token'))
    console.log(window.localStorage.getItem('token'))
    axios({
      method: 'post',
      url: 'mall_xcx/users/address-list',
      data: params
    }).then((res) => {
      res.data.data.list.forEach((element) => {
        address.push(element)
      })
    })
    // 去添加地址
    const goAddress = () => {
      store.push({ path: 'address' })
    }
    // 去管理地址
    const goAddressDisplay = () => {
      store.push({ path: 'addressDisplay' })
    }
    // 地址的选中情况
    const val = ref(0)
    const valClick = (v) => {
      val.value = v
    }
    return {
      address,
      data,
      reduce,
      add,
      num1,
      goAddress,
      goAddressDisplay,
      val,
      valClick,
      zj
    }
  }
}
</script>

<style lang='less' scoped>
.boss {
  .boss_content {
    width: 64%;
    margin: 0 auto;
    .shipToAddress {
      .title {
        font-size: 0.6rem;
      }
      .content {
        margin-top: 1rem;
        display: flex;
        margin-right: 2rem;
        .item {
          width: 12rem;
          height: 5.3rem;
          background-image: url(https://img.alicdn.com/tps/i2/T1VPiBXvpeXXbjLKQ7-237-106.png);
          background-repeat: no-repeat;
          padding: 0.2rem 0.3rem;
          overflow: hidden;
          box-sizing: border-box;
          .top {
            width: 100%;
            border-bottom: 0.2rem solid #f2f2f2;
            // padding: 0.5rem 0 0.2rem;
            // margin-bottom: 5rem;
            font-size: 0.8rem;
            font-weight: 700;
            box-sizing: border-box;
            color: #666;
            padding: 0.3rem 0.4rem;
          }
          .bottom {
            color: #666;
            font-size: 0.8rem;
            padding: 0.2rem 0.4rem;
          }
        }
        .items {
          background-image: url(https://img.alicdn.com/tfs/TB1OVRCRpXXXXaMXFXXXXXXXXXX-237-106.png);
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 0.8rem;
        .left {
          border-style: solid;
          background-color: #fff;
          border-color: #c4c6cf;
          border-radius: 0.3rem;
          padding: 0 0.6rem;
          height: 1.4rem;
          line-height: 1.4rem;
          font-size: 0.1rem;
          border-width: 0.1rem;
          text-align: center;
        }
        .right {
          color: #c97;
        }
      }
    }
    .information {
      margin-top: 1rem;
      .title{
        margin-bottom: 0.4rem;
      }
      .content {
        .top {
          display: flex;
          justify-content: space-between;
          div {
            border-bottom: 0.2rem solid #b2d1ff;
            text-align: center;
            line-height: 1.5rem;
            color: #6c6c6c;
          }
          div:nth-child(1) {
            width: 25.5rem;
          }
          div:nth-child(2) {
            width: 18rem;
          }
          div:nth-child(3) {
            width: 12rem;
          }
          div:nth-child(4) {
            width: 12rem;
          }
          div:nth-child(5) {
            width: 18rem;
          }
          div:nth-child(6) {
            width: 13rem;
          }
        }
      }
      .bottom {
        padding: 0.2rem;
        color: #333;
        margin: rem;
        padding: rem;
        border-bottom: 0.1rem dotted rgb(221, 221, 221);
        .content {
          display: flex;
          padding: 1rem 0;
          background-color: rgb(251, 252, 255);
          > div:nth-child(1) {
            width: 13rem;
            display: flex;
            div:nth-child(1) {
              img {
                width: 3rem;
              }
              margin-right: 0.2rem;
            }
            div:nth-child(2) {
              font-size: 0.6rem;
              overflow: hidden; /* 超出部分隐藏 */
              white-space: nowrap; /* 文本不换行 */
              text-overflow: ellipsis; /* 省略的文本用省略号表示 */
            }
          }
          > div:nth-child(2) {
            width: 18rem;
            font-weight: 400;
            color: #6c6c6c;
            text-align: center;
            font-size: 0.8rem;
          }
          > div:nth-child(3) {
            width: 12rem;
            text-align: center;
          }
          .number {
            // display: flex;
            // margin-top: 2rem;
            width: 12rem;
            .number_left {
              font-family: PingFangSC-Regular;
              font-size: 14rem;
              color: #7f7f7f;
              line-height: 18rem;
              margin-right: 1rem;
              width: 7rem;
            }
            .number_right {
              // display: flex;
              // padding-left: 13rem;
              .reduce,
              .add {
                // background-color: rgba(0, 0, 0, 0.06);
                text-align: center;
                line-height: 3rem;
                height: 3rem;
                width: 3rem;
                font-size: 0.8rem;
              }
              .num {
                text-align: center;
                // line-height: 3rem;
                // width: 40.75rem;
                // margin: 0 2rem;
                height: 3rem;
                // background-color: rgba(0, 0, 0, 0.06);
                font-size: 1rem;
                // margin: 0 0.5rem;
              }
              .redAdd {
                color: #ccc;
                background-color: rgba(0, 0, 0, 0.06);
              }
            }
          }
          > div:nth-child(5) {
            width: 18rem;
            text-align: center;
          }
          > div:nth-child(6) {
            width: 13rem;
            color: red;
            text-align: center;
          }
        }
        .address {
          display: flex;
          justify-content: space-between;
          margin-top: 0.2rem;
          > div {
            width: 49.4%;
            padding: 1rem;
            box-sizing: border-box;
            background-color: #f2f7ff;
            font-size: 0.1rem;
            color: #333;
          }
        }
      }
    }
    .confirmOrder {
      float: right;
      width: 15rem;
      box-sizing: border-box;
      padding: 0.6rem 0.4rem 0.6rem 0.2rem;
      border: 0.1rem solid red;
      text-align: right;
      margin-top: 1rem;
      font-size: 0.4rem;
      .ord {
        margin-top: 1rem;
        display: inline-block;
        width: 7rem;
        height: 2rem;
        position: relative;
        vertical-align: middle;
        line-height: 2rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.4rem;
        font-weight: 700;
        background-color: rgb(255, 0, 54);
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
</style>
