<template>
  <div class="bossa">
    <div class="boss_content">
      <div class="information">
        <div class="bottom">
          <div :class="checked?'content content_self':'content'" >
            <div>
              <div @click="checkedClick">
                <input type="checkbox" :checked="checked" />
              </div>
              <div @click="goSp(data.goodsId)">
                <img
                  :src="data.thumb"
                  alt=""
                  class="img"
                />
              </div>
              <div>
                {{ data.goodsName }}
              </div>
            </div>
            <div>{{data.goodsField}}</div>
            <div>￥{{ data.goodsPrice }}</div>
            <div class="number">
              <div class="number_right">
                <div
                  :class="num1 > 1 ? 'reduce' : 'reduce redAdd'"
                  @click="reduce"
                >
                  -
                </div>
                <div class="num">{{ num1 }}</div>
                <div class="add" @click="add">+</div>
              </div>
            </div>
            <div>￥{{ money }}</div>
            <div>
              <!-- <div>移入收藏夹</div> -->
              <div @click="deletes(data.id)">删除</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, h, ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import axios from '@/request/index'
export default {
  name: 'singleTon',
  props: {
    datas: Object,
    value: Number
  },
  setup (props, context) {
    // 获取vuex的值
    const store = useStore()
    const sto = useRouter()
    // 获取路由的传值
    const route = useRoute()
    // 获取订单信息
    const data = reactive(props.datas)
    const param = new URLSearchParams()
    param.append('goodsId', route.query.goodsId)
    // 定义购买产品数量
    const num1 = ref(data.goodsNum)
    // 减少商品数量
    const reduce = () => {
      if (num1.value > 1) {
        num1.value = num1.value - 1
      } else {
        ElMessage({
          message: h('p', null, [h('span', null, '数量低于范围')])
        })
      }
      if (checked.value) {
        context.emit('checkedSon', { num: money.value, value: props.value, checked: { checked: false, number: num1 } })
      }
    }
    // 增加产品数量
    const add = () => {
      num1.value = num1.value + 1
      if (checked.value) {
        context.emit('checkedSon', { num: money.value, value: props.value, checked: { checked: false, number: num1 } })
      }
    }
    // 监听数量的变化
    const money = computed(() => {
      // 返回的是ref对象
      return num1.value * parseFloat(data.goodsPrice)
    })
    // 是否选中该商品
    const checked = ref(false)
    const checkedClick = () => {
      checked.value = !checked.value
      if (!checked.value) {
        // console.log(222222222)
        store.commit('setChecked', false)
        context.emit('checkedSon', { num: 0, value: props.value, checked: { checked: false, number: num1 } })
      } else {
        context.emit('checkedSon', { num: money.value, value: props.value, checked: { checked: true, number: num1 } })
      }
    }
    // 监听vuex的值来确定是否全选
    const checkedWatch = computed(() => {
      // 返回的是ref对象
      return store.state.flag
    })
    watch(
      checkedWatch,
      (newVal) => {
        console.log(1111111111)
        checked.value = newVal
        if (newVal) {
          context.emit('checkedSon', { num: money.value, value: props.value, checked: { checked: true, number: num1 } })
        } else {
          context.emit('checkedSon', { num: 0, value: props.value, checked: { checked: false, number: num1 } })
        }
      },
      { immediate: true, deep: true }
    )
    // 定义金额变量

    // 删除
    const deletes = (v) => {
      const params1 = new URLSearchParams()
      params1.append('token', window.localStorage.getItem('token'))
      params1.append('cartIds', [v])
      axios({
        method: 'post',
        url: 'mall_xcx/cart/delete',
        data: params1
      }).then(res => {
        ElMessage(res.data.msg)
        if (res.data.code === 0) {
          console.log(111111111)
          context.emit('details', { details: props.value })
        }
      })
    }
    // 去往商品详情
    const goSp = (v) => {
      sto.push({ path: 'details', query: { goodsId: v, flag: 'zx' } })
    }

    return { data, num1, reduce, add, checked, checkedClick, money, deletes, goSp }
  }
}
</script>

<style scoped lang="less">
.bossa {
  .boss_content {
    .information {
      margin-bottom: 1rem;
      .bottom {
        padding: 1rem;
        color: #333;
        margin: 0rem;
        padding: 0rem;
        // border-bottom: 1rem dotted rgb(221, 221, 221);
        .content {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          background-color: #f5f5f5;
          border-radius: 1rem;
          box-sizing: border-box;
          > div:nth-child(1) {
            width: 33rem;
            display: flex;
            div:nth-child(2) {
              img {
                width: 6rem;
                display: block;
                border-radius: 0.2rem;
              }
              margin: 0 0.5rem;
            }
            div:nth-child(3) {
              width: 10rem;
              font-size: 0.4rem;
              overflow: hidden; /* 超出部分隐藏 */
              white-space: nowrap; /* 文本不换行 */
              text-overflow: ellipsis; /* 省略的文本用省略号表示 */
              margin-right: 1rem;
              img {
                width: 1rem;
                display: block;
                border-radius: 0.9rem;
              }
            }
          }
          > div:nth-child(2) {
            width: 11.7rem;
            font-weight: 400;
            color: #6c6c6c;
          }
          > div:nth-child(3) {
            width: 20rem;
            text-align: center;
          }
          .number {
            display: flex;
            // margin-top: 20rem;
            // width: 120rem;
            // margin-left: 42rem;
            .number_left {
              font-family: PingFangSC-Regular;
              font-size: 0.4rem;
              color: #7f7f7f;
              line-height: 18rem;
              margin-right: 10rem;
              width: 7rem;
            }
            .number_right {
              display: flex;
              padding-left: 0.13rem;
              .reduce,
              .add {
                background-color: rgba(0, 0, 0, 0.06);
                text-align: center;
                line-height: 1.5rem;
                height: 1.5rem;
                width: 1.5rem;
                font-size: 0.3rem;
              }
              .num {
                text-align: center;
                line-height: 1.5rem;
                width: 1.75rem;
                // margin: 0 0.1rem;
                height: 1.5rem;
                background-color: rgba(0, 0, 0, 0.06);
                font-size: 0.14rem;
                margin: 0 0.2rem;
              }
              .redAdd {
                color: #ccc;
                background-color: rgba(0, 0, 0, 0.06);
              }
            }
          }
          > div:nth-child(5) {
            width: 18rem;
            text-align: center;
            color: red;
          }
          > div:nth-child(6) {
            width: 13rem;
            text-align: center;
          }
        }
        .content_self {
          background: #FFF9F6;
          border: 0.1rem solid #FF5000;
        }
      }
    }
    .confirmOrder {
      float: right;
      width: 20rem;
      box-sizing: border-box;
      padding: 0.3rem 0.1rem 0.3rem 0.2rem;
      border: 0.1rem solid red;
      text-align: right;
      margin-top: 0.1rem;
      font-size: 0.4rem;
      .ord {
        margin-top: 1rem;
        display: inline-block;
        width: 18.2rem;
        height: 3.9rem;
        position: relative;
        vertical-align: middle;
        line-height: 3.9rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.4rem;
        font-weight: 700;
        background-color: rgb(255, 0, 54);
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
</style>
