<template>
  <div class="boss">
    <div class="content">
      <div class="item" v-for="item in data" :key="item.id">
        <Commodity
          :title="item.goodsName"
          :content="item.goodsDesc"
          :price="item.spe_price"
          :imgs="item.thumb"
          :goodsName="item.goodsName"
          @click="jump(item.id)"
        ></Commodity>
      </div>
    </div>
  </div>
</template>

<script>
import Commodity from '../../components/commodity.vue'
import { useRouter, useRoute } from 'vue-router'
import { reactive } from 'vue'
import axios from '@/request/index'
export default {
  name: 'commInd',
  components: {
    Commodity
  },
  setup () {
    // 路由组件
    const store = useRouter()
    const route = useRoute()
    // 获取商品数据
    const data = reactive([])
    if (route.query.flag === 'ly') {
      axios({
        url: 'gou/index/goods-list',
        method: 'post'
      }).then((res) => {
        res.data.data.list.forEach((element) => {
          data.push(element)
        })
      })
    } else {
      axios({
        url: 'mall_xcx/index/goods-list',
        method: 'post'
      }).then((res) => {
        res.data.data.list.forEach((element) => {
          data.push(element)
        })
      })
    }
    // 点击商品进入商品详情
    const jump = (v) => {
      store.push({ path: 'details', query: { goodsId: v, flag: route.query.flag } })
    }
    return { data, jump }
  }
}
</script>

<style lang="less" scoped>
.boss {
  background: #E0E7EA;
  min-height: 100vh;
  .content {
    padding: 3rem;
    width: 76%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    background: white;
    border-radius: 2rem;
    .item {
      width: 32%;
      margin-bottom: 1.1rem;
      // margin-right: 0.5rem;
      // mar
    }
    .item:nth-child(3n){
      margin-right: 0;
    }
  }
}
</style>
