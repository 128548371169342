<template>
  <div class="boss">
    <div class="content">
      <!-- 登录 -->
        <div class="isLogin">
      <div class="login" @click="goGwc">
        购物车
      </div>
      <div class="login" @click="isLogin" v-if="Login">
        登录
      </div>
      <div class="login" @click="noLogin" v-else>退出</div>
        </div>
      <!-- 导航区域 -->
      <div class="navigation">
        <div class="item" v-for="item in navigation" :key="item.id" @click="jump(item.pagePath)">
          {{ item.title }}
        </div>
      </div>
      <!-- 轮播图区域 -->
      <div class="banner">
        <el-carousel height='30rem' interval="2000">
          <el-carousel-item v-for="item in banner || 2" :key="item">
            <img class="bannerImg" :src="item" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 推荐商品 -->
      <div class="recommend">
        <div class="title">
          <div class="name">
            猜你喜欢
          </div>
          <img src="https://gw.alicdn.com/imgextra/i2/O1CN016b1mMM1FxJlsXfWhU_!!6000000000553-2-tps-96-30.png" alt="">
        </div>
        <div class="contentReco">
          <div class="item" v-for="item in listArr" :key="item.id">
        <Commodity
          :title="item.goodsName"
          :content="item.goodsDesc"
          :price="item.spe_price"
          :imgs="item.thumb"
          :goodsName="item.goodsName"
          @click="jumpSp(item.id)"
        ></Commodity>
      </div>
        </div>
      </div>
      <el-dialog v-model="centerDialogVisible" title="温馨提示" width="30%" center>
    <span
      >您还没有登录，是否前往登录</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onGo">立即前往</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >取消</el-button
        >
      </span>
    </template>
  </el-dialog>
    </div>
  </div>
</template>

<script>
import Commodity from '../../components/commodity.vue'
import { reactive, ref } from 'vue'
import axios from '@/request/index'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
export default {
  name: 'indexSy',
  components: {
    Commodity
  },
  setup () {
    // 路由组件
    const store = useRouter()
    // 跳转页面功能
    const jump = (v) => {
      // const s = ''
      if (v === '/pages/integral/integral') {
        store.push({ path: 'lyg', query: { flag: 'zx' } })
      } else if (v === '/pages/nearbymerchants/nearbymerchants') {
        store.push({ path: 'nearby' })
      } else {
        store.push({ path: 'lyg', query: { flag: 'ly' } })
      }
      // store.push({ path: 'lyg', query: { flag: s } })
    }
    const centerDialogVisible = ref(false)
    // 获取轮播图与导航
    const banner = reactive(['http://youquanyun.oss-cn-beijing.aliyuncs.com/uploads/image/412/0/2022/09/c7e03c6520ef640eef60da3a24be5791.jpg_WH_1024X516.jpg'])
    const navigation = reactive([])
    axios({
      url: 'mall_xcx/oto/display',
      method: 'get'
    }).then((res) => {
      banner.length = 0
      res.data.data.slide.forEach((element) => {
        banner.push(element.thumb)
      })
      res.data.data.displayNavList.forEach((element) => {
        navigation.push(element)
      })
    })
    // 获取推荐商品数据
    const listArr = reactive([])
    const param = new URLSearchParams()
    param.append('cid', 0)
    axios({
      url: 'mall_xcx/index/goods-list',
      method: 'post',
      data: param
    }).then((res) => {
      res.data.data.list.forEach(res => {
        listArr.push(res)
      })
    })
    // 前往商品详情
    const jumpSp = (v) => {
      store.push({ path: 'details', query: { flag: 'zx', goodsId: v } })
    }
    // 去登录
    const isLogin = () => {
      store.push('login')
    }
    // 前往购物车
    const goGwc = () => {
      if (window.localStorage.getItem('token')) {
        store.push('shoppingCart')
      } else {
        centerDialogVisible.value = true
      }
    }
    const onGo = () => {
      store.push({ path: 'login' })
      centerDialogVisible.value = false
    }
    // 判断是否登录
    const Login = ref(false)
    if (window.localStorage.getItem('token')) {
      Login.value = false
    } else {
      Login.value = true
    }
    // 退出登录
    const noLogin = () => {
      Login.value = true
      ElMessage({
        type: 'success',
        message: '退出成功'
      })
      window.localStorage.removeItem('token')
      store.push('')
    }
    return { navigation, jump, banner, isLogin, goGwc, centerDialogVisible, onGo, Login, noLogin, listArr, jumpSp }
  }
}
</script>

<style lang="less" scoped>
.boss {
  background: #E9E5E6;
  padding: 0.4rem 10%;
  min-height: 100vh;
  .content {
    background: white;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    .isLogin{
      overflow: hidden;
      height: 2rem;
    }
    .login{
      text-align: right;
      width: 4rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      color: white;
      float: right;
      background: skyblue;
      margin-right: 2rem;
      // margin-top: -5rem;

    }
    .navigation {
      width: 90%;
      margin: 2rem auto;
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
    }
    .banner{
        .bannerImg{
            width: 100%;
        }
    }
    .recommend{
      // height: 4.8px;
      margin-bottom: 0.20px;
      margin-left: 0.2px;
      // padding: 0 20%;
      .title{
        display: flex;
        margin: 1rem 0;
        .name{
          // float: left;
          color: #111;
          font-weight: bold;
          font-size: 1.2rem;
          line-height: 2rem;
        }
        img{
          display: block;
          height: 1.2rem;
          margin: 0.5rem 0.4rem;
        }
      }
      .contentReco{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 1.5rem;
        .item{
          width: 32%;
          // margin-right: 1rem;
          margin-bottom: 1rem;
        }
        .item:nth-child(3n){
          margin-right: 0rem;
        }
      }
    }
  }
}
</style>
