<template>
  <div class="boss">
    <div class="boss_content">
      <div class="title">购物车</div>
      <div class="information_content">
        <div class="information_top">
          <!-- <div @click="checkedClick">
            <input type="checkbox" :checked="checked" />
            全选
          </div> -->
          <div></div>
          <div>商品信息</div>
          <div>单价</div>
          <div>数量</div>
          <div>金额</div>
          <div>操作</div>
        </div>
      </div>
      <!-- <Singleton :datas="data" @checkedSon='son' :value='0'></Singleton> -->
      <div v-for="(item,value) in data" :key="item.goods.id">
        <div class="xqItem"><img :src="item.shopInfo.logo" alt="" class="img"> <div>{{item.shopInfo.title}}</div></div>
        <div v-for="(item1,value1) in item.goods" :key="item1.id">
            <Singleton :datas="item1" @checkedSon='son' :value='aa[value][value1]' @details='details'></Singleton>
        </div>
      </div>
      <div class="settlement">
        <div></div>
        <div style="display:flex;">
          <div>已选商品<text style="color: #f40; font-size:1rem;">{{number}}</text>件</div>
          <div>合计(不含运费)： <text style="color: #f40; font-size:1rem;">￥{{total}}</text></div>
          <div @click="settlement">结算</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/request/index'
import { reactive, ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import Singleton from '@/components/single'
import { useStore } from 'vuex'
import { isArray } from '@vue/shared'
export default {
  components: {
    Singleton
  },
  setup () {
    const sto = useRouter()
    // 获取vuex的值
    const store = useStore()
    // 获取路由的传值
    // const route = useRoute()
    // 获取购物车信息
    const data = reactive([])
    const param = new URLSearchParams()
    const checkedArr = ref([])
    param.append('token', window.localStorage.getItem('token'))
    const dataAxios = (v) => {
      axios({
        method: 'post',
        url: 'mall_xcx/cart/list',
        data: param
      }).then((res) => {
        // arr.value.length = 0
        i = 0
        aa.value.length = 0
        checkedArr.value.length = 0
        data.length = 0
        res.data.data.list.forEach(res => {
          data.push(res)
        })
        data.forEach((res, value) => {
        // console.log(1111111)
          if (!isArray(aa.value[value])) {
            aa.value[value] = []
            // console.log(aa.value)
          }
          res.goods.forEach((re, value1) => {
          // console.log(1111111)
            checkedArr.value.push({ checked: false, goodsId: re.goodsId, flag: 'zx', goodsNum: re.goodsNum })
            if (!v) {
              arr.value.push(0)
            }
            aa.value[value].push(i++)
          })
        })
        if (v === 'aa') return
        son(v)
      })
    }
    dataAxios('aa')
    // 是否全选
    const checked = ref(store.state.checked)
    const checkedClick = () => {
      checked.value = !checked.value
      store.commit('setChecked', 'js')
    }
    // 监听vuex的值来确定是否全选
    const checkedWatch = computed(() => {
      // 返回的是ref对象
      return store.state.checked
    })
    watch(
      checkedWatch,
      (newVal) => {
        checked.value = newVal
        // store.commit('setChecked', newVal)
      },
      { immediate: true, deep: true }
    )
    // 每个商品最后的结算价
    const arr = ref([])
    const aa = ref([])
    let i = 0
    // 总价
    const total = ref('00.00')
    // 初始化总价
    arr.value.forEach(element => {
      total.value = parseFloat(total.value) + element
    })
    // 选中商品的数量
    const number = ref(0)
    // 子传父用以结算总价
    const son = (v) => {
      const a = typeof v
      console.log(v)
      if (a === 'object') {
        arr.value[v.value] = v.num
        checkedArr.value[v.value].goodsNum = v.checked.number
        checkedArr.value[v.value].checked = v.checked.checked
      } else {
        console.log(v)
        arr.value.splice(v.details, 1)
      }
      // 计算总价
      total.value = 0
      number.value = 0
      arr.value.forEach(element => {
        total.value = parseFloat(total.value) + element
        if (element > 0) {
          number.value++
        }
      })
      if (number.value === arr.value.length) {
        checked.value = true
        store.commit('setChecked', true)
        store.state.flag = true
      } else {
        checked.value = false
        // store.state.flag = false
        store.commit('setChecked', false)
      }
    }
    // 结算
    const settlement = () => {
      const ss = []
      checkedArr.value.forEach(res => {
        if (res.checked) {
          ss.push(res)
        }
      })
      const obj = {
        ss
      }
      sto.push({ path: 'confiem', query: { name: 'gwc', total: total.value, obj: JSON.stringify(obj) } })
    }
    // 删除
    const details = (v) => {
      dataAxios(v)
    }
    return { data, checkedClick, checked, arr, total, son, number, aa, settlement, details, checkedArr }
  }
}
</script>

<style scoped lang="less">
.boss {
  background: #e8dfd9;
  padding: 2rem;
  .boss_content {
    width: 80%;
    margin: 0 auto;
    padding: 2rem;
    background: white;
    border-radius: 2rem;
    min-height: 100vh;
    .title {
      overflow: hidden;
      font-size: 2rem;
      position: relative;
      // height: 72rem;
      line-height: 4rem;
      padding: 0 0.2rem;
      border-bottom: 0.1rem solid #e6e6e6;
    }
    .information_content {
      margin-top: 3rem;
      .information_top {
        display: flex;
        justify-content: space-between;
        padding-right: 0.3rem;
        div {
          // border-bottom: 3rem solid #b2d1ff;
          // margin-bottom: 2rem;
          text-align: center;
          line-height: 0.4rem;
          color: #6c6c6c;
        }
        div:nth-child(1) {
          width: 16rem;
        }
        div:nth-child(2) {
          width: 30rem;
          text-align: left;
        }
        div:nth-child(3) {
          width: 17rem;
        }
        div:nth-child(4) {
          width: 9rem;
        }
        div:nth-child(5) {
          width: 18rem;
        }
        div:nth-child(6) {
          width: 13rem;
        }
      }
    }
    .xqItem{
      display: flex;
        .img{
          width: 3rem;
          height: 3.2rem;
          // display: block;
          margin-right: 1rem;
        }
        line-height: 3rem;
        font-size: 0.8rem;
      }
    .settlement {
      display: flex;
      justify-content: space-between;
      height: 2.72rem;
      border-top: 0.1rem solid #e6e6e6;
      line-height: 2.72rem;
      // letter-spacing:2rem;
      overflow: hidden;
      font-size: 0.8rem;
      // margin: 0 6rem;
      div:nth-child(2) {
        display: flex;
        div:nth-child(1) {
          margin-right: 1rem;
        }
        div:nth-child(3){
          width: 5rem;
          height: 2rem;
          line-height: 2rem;
          color: #fff;
          background: #FF5000;
          border-radius: 1rem;
          text-align: center;
          margin-top: 0.4rem;
          margin-left: 0.6rem;
        }
      }
    }
  }
}
</style>
