import { createRouter, createWebHashHistory } from 'vue-router'
import fontPage from '../views/frontPage/index.vue'
import commodityInd from '../views/commodity/index.vue'
import details from '../views/commodity/details.vue'
import confirmOrder from '../views/commodity/confirmOrder.vue'
import shoppingCart from '../views/commodity/shoppingCart.vue'
import address from '../views/commodity/address.vue'
import addressDisplay from '../views/commodity/addressDisplay.vue'
import login from '../views/login.vue'
import nearby from '../views/nearby/index.vue'
import shopDetails from '../views/nearby/shopDetails.vue'
import paymentSj from '../views/nearby/paymentSd.vue'
import information from '../views/nearby/informationSon.vue'
const routes = [
  // 首页
  {
    path: '/',
    name: 'fontPage',
    component: fontPage
  },
  // 商品展示
  {
    path: '/lyg',
    name: 'commodityInd',
    component: commodityInd
  },
  // 商品详情
  {
    path: '/details',
    name: 'detailss',
    component: details
  },
  // 立即购买
  {
    path: '/confiem',
    name: 'confirmOrder',
    component: confirmOrder
  },
  // 购物车
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: shoppingCart
  },
  // 添加地址
  {
    path: '/address',
    name: 'address',
    component: address
  },
  // 地址展示
  {
    path: '/addressDisplay',
    name: 'addressDisplay',
    component: addressDisplay
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: login
  },
  // 附近商家
  {
    path: '/nearby',
    name: 'nearby',
    component: nearby
  },
  // 店铺详情
  {
    path: '/shopDetails',
    name: 'shopDetails',
    component: shopDetails
  },
  // 店铺支付
  {
    path: '/paymentSj',
    name: 'paymentSj',
    component: paymentSj
  },
  // 商家信息
  {
    path: '/information',
    name: 'information',
    component: information
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
