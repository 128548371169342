/**
 * axios二次封装
 */
import axios from 'axios'
// import Qs from 'qs'
// import router from '@/router'
// import { ElMessage } from 'element-plus'
// 创建axios实例对象，添加全局配置
// axios.defaults.headers.post['content-type'] = 'application/x-www-form-urlencoded'
const service = axios.create({
  baseURL: 'https://zlhuigou.xmxhsh.cn/api/412/',
  timeout: 80000
})

// 请求拦截
service.interceptors.request.use((req) => {
  //   const token = localStorage.getItem('token')
  //   token && (req.headers.Authorization = `Bearer${JSON.parse(token)}`)
  req.headers.post['content-type'] = 'application/x-www-form-urlencoded'
  return req
})

// 响应拦截
// service.interceptors.response.use((res) => {
// //   const { status_code, data, message } = res.data
// //   if (status_code === 200) {
// //     return data
// //   } else {
// //     ElMessage.error(message)
// //     // router.push('login')
// //     return Promise.reject(message)
// //   }
// })
/**
 * 请求核心函数
 * @param {*} options 请求配置
 */
function request (options) {
  options.method = options.method || 'get'
  if (options.method.toLowerCase() === 'get') {
    options.params = options.data
  }
  return service(options)
}

['get', 'post', 'put', 'delete', 'patch'].forEach((item) => {
  if (item === 'post') {
    request[item] = (url, data, options) => {
      return request({
        url,
        data,
        method: item,
        ...options
      })
    }
  } else {
    request[item] = (url, data, options) => {
      return request({
        url,
        data,
        method: item,
        ...options
      })
    }
  }
})

export default request
