<template>
  <router-view/>
</template>

<style lang="less">
   html {
    font-size: 20px;
   }
   *{
    margin:  0;
    padding: 0;
   }
</style>
