<template>
  <div class="boss">
    <div class="content">
      <el-form :model="form" label-width="120px">
        <el-form-item label="姓名">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.pho" />
        </el-form-item>
        <!-- <el-form-item label="地址">
          <el-input v-model="form.address" />
        </el-form-item> -->
        <el-form-item label="地址">
          <elui-china-area-dht @change="onChange"></elui-china-area-dht>
        </el-form-item>
        <!-- <elui-china-area-dht @change="onChange"></elui-china-area-dht> -->
        <el-form-item label="详细地址">
          <el-input v-model="form.add" />
        </el-form-item>
        <el-form-item label="默认">
          <div>
            <div class="add">
              <div>设置为默认地址</div>
              <el-switch v-model="form.value1" />
            </div>
            <div>提醒：每次下单会默认推荐该地址</div>
          </div>
        </el-form-item>
        <button class="but" @click="but($event)">提交</button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import axios from '@/request/index'
import { EluiChinaAreaDht } from 'elui-china-area-dht'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat
export default {
  components: {
    EluiChinaAreaDht
  },
  name: 'addresS',
  setup () {
    const route = useRoute()
    const form = reactive({
      name: '',
      pho: '',
      province: '',
      city: '',
      district: '',
      add: '',
      value1: false
    })
    if (route.query.id) {
      const params = new URLSearchParams()
      params.append('token', window.localStorage.getItem('token'))
      params.append('id', route.query.id)
      axios({
        method: 'post',
        url: 'mall_xcx/users/address-post',
        data: params
      }).then((res) => {
        const data = res.data.data
        form.name = data.username
        form.pho = data.mobile
        form.province = data.province
        form.city = data.city
        form.district = data.district
        form.add = data.address
        if (data.isdefault === 0) {
          form.value1 = false
        } else {
          form.value1 = true
        }
      })
    }
    const onChange = (e) => {
      console.log(chinaData[e[0]].label, chinaData[e[1]], chinaData[e[2]])
      form.province = chinaData[e[0]].label
      form.city = chinaData[e[1]].label
      form.district = chinaData[e[2]].label
    }
    const but = (v) => {
      v.preventDefault()
      const param = new URLSearchParams()
      param.append('token', window.localStorage.getItem('token'))
      param.append('username', form.name)
      param.append('mobile', form.pho)
      param.append('province', form.province)
      param.append('address', form.add)
      param.append('city', form.city)
      param.append('district', form.district)
      param.append('type', 'save')
      if (form.value1) {
        param.append('isdefault', 1)
      } else {
        param.append('isdefault', 0)
      }
      if (route.query.id) {
        param.append('id', route.query.id)
      }
      param.append('district', form.district)
      axios({
        method: 'post',
        url: 'mall_xcx/users/address-post',
        data: param
      }).then((res) => {
        if (res.data.code !== 0) {
          ElMessage(res.data.msg)
        } else {
          form.name = ''
          form.pho = ''
          form.province = ''
          form.city = ''
          form.district = ''
          form.add = ''
          form.value1 = false
          ElMessage(res.data.msg)
          history.go(-1)
        }
      })
    }
    return { form, but, onChange }
  }
}
</script>

<style lang='less' scoped>
.boss {
  .content {
    width: 60%;
    margin: 50px auto;
    padding: 10px 20px;
    .add {
      display: flex;
      justify-content: space-between;
    }
    .but {
      display: block;
      width: 90%;
      margin: 20px auto;
      background: skyblue;
      line-height: 50px;
      text-align: center;
      color: white;
      border: none;
    }
  }
}
</style>
