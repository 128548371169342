<template>
  <div style="padding: 0 20%;">
    <div class="paybox">
      <div class="goodsname">
        <div>
          <img :src="storeobj.logo" mode="" class="goodimg" />
        </div>
        <div style="margin-left: 24px">
          {{ storeobj.title }}
        </div>
      </div>
      <div style="margin: 20px">
        <div class="">输入支付金额</div>
      </div>
      <div>
        <input
          type="text"
          placeholder="请输入金额"
          class="payinput"
          v-model="money"
        />
      </div>
    </div>
    <div class="paybtn">
      <button type="default" class="btn" @click="pay()">立即购买</button>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import axios from '@/request/index'
import { useRoute } from 'vue-router'
export default {
  name: 'paymentSd',
  setup () {
    const route = useRoute()
    // 获取数据
    const storeobj = reactive({})
    const param = new URLSearchParams()
    param.append('spid', route.query.goodsId)
    param.append('token', window.localStorage.getItem('token'))
    axios({
      url: 'mall_xcx/oto/pay',
      method: 'post',
      data: param
    }).then((res) => {
      for (const item in res.data.data.shopInfo) {
        storeobj[item] = res.data.data.shopInfo[item]
      }
    })
    // 立即购买
    const pay = () => {

    }
    return { storeobj, pay }
  }
}
</script>
<style lang="less" scoped>
.paybox {
  padding: 24px;
  background-color: #ffffff;
  .goodsname {
    display: flex;
    border-bottom: 2px solid #e1e1e1;
    height: 250px;
    .goodimg {
      width: 200px;
      height: 200px;
    }
  }
}
.paybtn {
  margin-top: 100px;
//   padding: 0 100px;
  .btn {
    background-color: red;
    color: #ffffff;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    height: 80px;
    width: 1000px;
  }
}
.payinput {
  background-color: #efefef;
  height: 80px;
//   padding-left: 80px;
  box-sizing: border-box;
  position: relative;
  margin-top: 40px;
  width: 800px;
  display: block;
  margin: 40px auto;
  &::before {
    content: "￥";
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
  }
}
</style>
