<template>
  <div class="boss">
    <!-- <u-swiper :list="list1" height="700rem" imgMode="aspectFill  "></u-swiper> -->
    <el-carousel trigger="click" height="30rem">
      <el-carousel-item v-for="item in list1" :key="item">
        <img :src="item" alt="" style="width:100%">
      </el-carousel-item>
    </el-carousel>
    <div>
      <div class="goodsboxs">
        <div class="namebox">
          <div style="font-weight: 600; font-size: 1.34rem">
            {{ shopInfo.title }}
          </div>
          <!-- <u-rate :count="5" readonly v-model="value" size="12"></u-rate> -->
          <div class="zhuanqu">
            <div class="quanlieb_item">
              <div class="item1">赠</div>
              <div class="item2">
                <div class="item2_one">{{ shopInfo.sub_rebate }}%补贴值</div>
                <div class="item2_two">{{ shopInfo.self_rebate }}%贡献值</div>
              </div>
              <div class="item3">
                {{
                  parseFloat(shopInfo.sub_rebate) +
                  parseFloat(shopInfo.self_rebate)
                }}%
              </div>
            </div>
            <div style="line-height: 1">人气92</div>
          </div>
        </div>
        <div>
          <div class="addresbox">
            <div>
              {{ shopInfo.address }}
            </div>
            <div class="addresright">
              <div class="righticon" @click="maps">
                <!-- <u-icon size="22" name="map"></u-icon> -->
              </div>
              <div class="icon2" @click="pho">
                <!-- <u-icon size="22" name="phone"></u-icon> -->
              </div>
            </div>
          </div>
          <div
            class=""
            style="font-size: 0.24rem"
            v-if="shopInfo.hoursInfo.status === '休息中'"
          >
            {{ shopInfo.hoursInfo.status }}
          </div>
          <div style="font-size: 0.24rem" v-else>
            {{ shopInfo.hoursInfo.status }}：{{
              shopInfo.hoursInfo.starttime
            }}
            - {{ shopInfo.hoursInfo.endtime }}
          </div>
        </div>
      </div>
    </div>
    <div class="msgbox">
      <div class="msglis" @click="goClick('store_msg')">
        <div style="display:flex;">
          <el-icon :size="35"><Handbag color='red'/></el-icon>
          <div style="margin-left:0.10rem; font-size:0.26rem;">商家信息</div>
        </div>
      </div>
      <div class="msglis">
        <!-- <u-icon
          name="integral"
          color="red"
          size="24"
          label="会员卡领取"
        ></u-icon> -->
        <div style="display:flex;">
          <el-icon :size="35"><Postcard color='red'/></el-icon>
          <div style="margin-left:0.10rem; font-size:0.26rem;">会员卡领取</div>
        </div>
        <!-- <u-icon name="arrow-right" size="28"></u-icon> -->
      </div>
      <div class="msglis">
        <!-- <u-icon name="coupon" color="red" size="24" label="优惠券领取"></u-icon>
        <u-icon name="arrow-right" size="18"></u-icon> -->
        <div style="display:flex;">
          <el-icon :size="35"><Connection color='red'/></el-icon>
          <div style="margin-left:0.10rem; font-size:0.26rem;">优惠券领取</div>
        </div>
      </div>
    </div>

    <div class="online">
      <div class="onlineContent">
        <div class="title">在售商品</div>
        <div class="goodsbox_item" v-if="goodsList.length != 0">
          <div
            class="goodslis"
            v-for="(item, index) in goodsList"
            :key="index"
            @click="jumpClick(item.id)"
          >
            <div class="ticketimgbox">
              <img :src="item.thumb" mode="" class="img" />
              <div class="topdiscount">{{ item.unityTitle }}</div>
            </div>
            <div class="goodstext">
              <div>
                <text>{{item.goodsName}}</text>
              </div>
              <div class="addres">
                <div
                  style="height: 0.6rem"
                  class=""
                >
                  <!-- <u-rate :count="count" v-model="value" readonly size="14"></u-rate> -->
                  <!-- <text class="juli">5.0</text> -->
                  <div style="color: red">￥{{ item.spe_price }}</div>
                  <div class="">已售 {{ item.sales }}{{ item.unit }}</div>
                  <button class="but_lyg" @click="qg(item.id)">抢购</button>
                </div>
                <!-- <div style="font-size: 22rem;">700m</div> -->
              </div>
              <!-- <div class="quanlieb">
                <div style="color: red">￥{{ item.spe_price }}</div>
                <button class="but_lyg" @click="qg(item.id)">抢购</button>
              </div> -->
            </div>
          </div>
        </div>
        <div v-else style="margin-top: 1rem">
          <!-- <u-divider text="暂无商品"></u-divider> -->
        </div>
      </div>
    </div>
    <div style="height: 1.1rem"></div>
    <div class="paybtnbox">
      <button type="default" class="paybtn" @click="goPay('storeorder')">
        我要买单
      </button>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/request/index'
import { Postcard, Handbag, Connection } from '@element-plus/icons-vue'
export default {
  name: 'nearbyIndex',
  components: {
    Handbag,
    Postcard,
    Connection
  },
  setup () {
    // 获取vuex的值
    const route = useRoute()
    const store = useRouter()
    // 获取数据
    const shopInfo = reactive({})
    // 定义顶部轮播
    const list1 = reactive([])
    const param = new URLSearchParams()
    param.append('spid', route.query.goodsId)
    axios({
      url: 'mall_xcx/oto/shop-info',
      method: 'post',
      data: param
    }).then((res) => {
      for (const item in res.data.data.shopInfo) {
        shopInfo[item] = res.data.data.shopInfo[item]
      }
      res.data.data.slides.forEach(res => {
        list1.push(res.thumb)
      })
    })
    // 获取在售商品
    const goodsList = reactive([])
    axios({
      url: 'mall_xcx/oto/goods-list',
      method: 'post',
      data: param
    }).then((res) => {
      if (res.data.data.list.length === 0) return
      res.data.data.list.forEach((element) => {
        goodsList.push(element)
      })
    })
    const goClick = () => {
      const obj = {
        image: shopInfo.info,
        title: shopInfo.title,
        logo: shopInfo.logo
      }
      store.push({ path: 'information', query: { obj: JSON.stringify(obj) } })
    }
    // 获取金纬度
    const goPay = () => {
      store.push({ path: 'paymentSj', query: { goodsId: route.query.goodsId } })
    }
    const jumpClick = (v) => {
      store.push({ path: 'details', query: { goodsId: v, flag: 'zx' } })
    }
    return { goodsList, shopInfo, jumpClick, list1, goPay, goClick }
  }
}
</script>
<style lang="less" scoped>
.boss{
    padding: 0 30%;
    padding-bottom: 3rem;
}
.online {
  background-color: white;
  margin-top: 1rem;
  .onlineContent {
    padding: 0.10rem 0.2rem;
    .title {
      line-height: 1.4rem;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}
// 轮播图图片样式
.quanlieb_item {
  // margin-top: 10rem;
  // width: 210rem;
  // height: 56rem;
  margin-top: 0rem;
  border-radius: 0.7rem 0 0.7rem 0;
  display: flex;
  border: 0.1rem solid rgba(255, 215, 000, 1);
  overflow: hidden;
  // overflow: hidden;
  .item1 {
    // border-radius: 0.3rem 0 0 0;
    line-height: 1.3rem;
    padding: 0 0.2rem;
    color: red;
    font-size: 0.5rem;
    text-align: center;
    background-color: rgba(255, 215, 000, 1);
  }
  .item2 {
    font-size: 0.20rem;
    // border-left: 2rem solid;
    // border-right: 2rem solid;
    text-align: center;
    line-height: 1;
    .item2_one {
      padding: 0 0.6rem;
      border-bottom: 0.1rem solid rgba(255, 215, 000, 1);
    }
  }
  .item3 {
    border-radius: 0 0 0.30rem 0;
    background-color: rgba(255, 215, 000, 1);
    font-size: 0.22rem;
    line-height: 1.3rem;
    text-align: center;
    padding: 0 0.10rem;
    color: red;
  }
}
.u-swiper__wrapper__item__wrapper {
  position: relative;
}
.u-swiper__wrapper__item__wrapper__img {
  position: absolute;
  top: 0;
  display: block;
}
.goodsboxs {
  background-color: #ffffff;
  padding: 0.24rem;
  box-sizing: border-box;

  .namebox {
    line-height: 2.5;
    border-bottom: 0.2rem solid #e1e1e1;

    .zhuanqu {
      color: #9e9e9e;
      font-size: 0.26rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.10rem;
    }
  }

  .addresbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1rem;
    margin-top: 1rem;
    font-size: 0.7rem;
    .addresright {
      display: flex;

      .righticon {
        width: 0.80rem;
        border-right: 0.2rem solid #9e9e9e;
      }

      .icon2 {
        width: 0.80rem;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.msgbox {
  padding: 0 0.24rem;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 0.24rem;

  .msglis {
    display: flex;
    justify-content: space-between;
    height: 3rem;
    line-height: 2rem;
    align-items: center;
    border-bottom: 0.2rem solid #e1e1e1;
    // &:last-child {
    //   border-bottom: 0rem;
    // }
  }
}
.goodsbox_item {
  margin: 0.24rem 0;
  padding: 0 0.24rem;
  box-sizing: border-box;
  .newticketdetaidesc {
    width: 71%;
    height: 0.77rem;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 0.36rem;
    color: #666666;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .ticketimgbox {
    position: relative;
    margin-right: 1rem;
  }
  .topdiscount {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ff4f0d;
    color: #ffffff;
    font-size: 0.20rem;
    /* width: 64rem; */
    border-bottom-right-radius: 20rem;
    height: 0.40rem;
    text-align: center;
    line-height: 0.40rem;
    border-radius: 0.16rem 0rem 0.16rem 0rem;
    padding: 0 0.10rem;
  }
  .goodslis {
    display: flex;
    // align-items: center;
    background-color: #ffffff;
    padding: 0.24rem;
    box-sizing: border-box;
    border-radius: 0.16rem;
    margin-bottom: 0.24rem;

    .img {
      width: 7rem;
      height: 7rem;
    }

    .goodstext {
      margin-left: 0.24rem;
      flex: 1;
      line-height: 2;
    }

    .addres {
      display: flex;
      justify-content: space-between;
    }

    .juli {
      font-size: 0.24rem;
      color: red;
      margin-left: 0.10rem;
    }

    .quanlieb {
      display: flex;
      justify-content: space-between;
      margin-top: 0.10rem;

      .button {
        margin: 0;
      }
    }

    .quan {
      font-size: 0.22rem;
      padding: 0.3rem 0.14rem;
      background-color: red;
      color: #ffffff;
      border-radius: 0.16rem;
    }
    .but_lyg {
      width: 3rem;
      height: 1.5rem;
      background-color: red;
      color: #ffffff;
      font-size: 0.20rem;
      line-height: 0.40rem;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
}
.paybtnbox {
  height: 2rem;
  position: fixed;
  bottom: 0rem;
  left: 50%;
  transform: translate(-50%, 0);
  .paybtn {
    width: 40rem;
    background-color: red;
    color: #ffffff;
    height: 100%;
    border-radius: 0;
    font-size: 0.28rem;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
