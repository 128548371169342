<template>
  <div>
    <el-form :model="rulesForm" :rules="rules" ref="rulesRef">
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="rulesForm.phone" placeholder="请输入手机号">
        </el-input>
      </el-form-item>
      <el-form-item label="验证码：" prop="countDown">
        <el-row :gutter="24">
          <el-col :span="18">
            <el-input v-model="rulesForm.countDown" placeholder="验证码"></el-input>
          </el-col>
          <el-col :span="6">
            <el-button @click="sendCode" :disabled="disabled">{{ btnText }}</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleLogin">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { defineProps, ref, reactive, watch, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import axios from '@/request/index'
const checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空！'))
  } else {
    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号！'))
    }
  }
}

const rulesForm = ref({})
const disabled = ref(false)
const btnText = ref('发送验证码')

const props = defineProps({
  ruleForm: {
    type: Object,
    required: true
  },
  countDown: {
    type: Number,
    required: true
  }
})
const emits = defineEmits(['sendCode'])
const rules = reactive({
  phone: [{ required: true, trigger: 'change', validator: checkPhone }],
  countDown: [{ required: true, message: '验证码不能为空' }]
})
const rulesRef = ref(null)
const time = ref(0) // 设置倒计时

// 发送验证码
const sendCode = () => {
  // 手机号必须输入正确，如果不正确，就提示
  rulesRef.value.validateField('phone', errMessage => {
    if (!errMessage) {
      ElMessage({
        type: 'error',
        message: '请输入正确的手机号'
      })
    } else {
      // 1、时间开始倒数
      // 2、按钮进入禁用状态
      // 3、如果倒计时结束，按钮恢复可用状态，按钮文字变成重新发送
      // 4、倒计时的过程中，按钮文字为多少秒后重新发送
      time.value = props.countDown
      const timer = setInterval(() => {
        time.value--
        btnText.value = `${time.value}s后重新发送`
        disabled.value = true
        if (time.value === 0) {
          disabled.value = false
          btnText.value = '重新发送'
          time.value = props.countDown
          clearInterval(timer)
        }
      }, 1000)
      emits('sendCode')
    }
  })
}

// 登录功能
const handleLogin = () => {
  rulesRef.value.validate(valid => {
    if (valid) {
      // emits('onSubmit')
      const params1 = new URLSearchParams()
      params1.append('biz_id', 412)
      params1.append('mobile', props.ruleForm.phone)
      params1.append('sms_code', props.ruleForm.countDown)
      axios({
        method: 'post',
        url: 'amoy/auth/login',
        data: params1
      }).then(res => {
        if (res.data.code === 0) {
          window.localStorage.setItem('token', res.data.data.token)
          ElMessage({
            type: 'success',
            message: res.data.msg
          })
          history.go(-1)
        } else {
          ElMessage({
            type: 'success',
            message: res.data.msg
          })
        }
      })
    } else {
      emits('onError')
    }
  })
}
watch(
  () => props.ruleForm,
  val => {
    rulesForm.value = val
  },
  { immediate: true }
)

</script>

<style scoped>

</style>
