import { createStore } from 'vuex'

export default createStore({
  state: {
    checked: false,
    flag: false
  },
  getters: {
  },
  mutations: {
    setChecked (state, data) {
      if (data === false) {
        state.checked = data
        if (data === 'js') {
          state.flag = data
        }
      } else {
        if (data === 'js') {
          state.flag = !state.checked
        }
        state.checked = data
      }
      // console.log(data)
    }
  },
  actions: {
  },
  modules: {
  }
})
