<template>
  <div class="login-panel">
    <div class="phone-login">
      <Bbc
        :ruleForm="ruleForm"
        :countDown="countDown"
        @sendCode="sendCode"
        @onSubmit="onSubmit"
        @onError="onError"
      >
      </Bbc>
    </div>
  </div>
</template>

<script >
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import Bbc from '../components/loginSe.vue'
import axios from '@/request/index'
export default {
  name: 'loginSi',
  components: {
    Bbc
  },
  setup () {
    const ruleForm = reactive({
      phone: '',
      countDown: ''
    })
    const countDown = ref(30)

    const sendCode = () => {
      // 发送验证码接口
      const params1 = new URLSearchParams()
      params1.append('biz_id', 412)
      params1.append('mobile', ruleForm.phone)
      params1.append('type', 'login')
      axios({
        method: 'post',
        url: 'amoy/auth/sms-verifycode',
        data: params1
      }).then((res) => {
        ElMessage({
          type: 'success',
          message: res.data.msg
        })
      })
    }

    const onSubmit = () => {
      alert('发送 http请求：')
      ElMessage({
        type: 'success',
        message: '保存成功'
      })
    }

    const onError = () => {
      ElMessage({
        type: 'warning',
        message: '校验失败'
      })
    }
    return { ruleForm, countDown, sendCode, onSubmit, onError }
  }
}
</script>

<style scoped lang="less">
.login-panel {
  margin-left: 2rem;
  margin-top: 2rem;
}

.phone-login {
  width: 21.9rem;
  height: 35rem;
  margin: 10rem auto;
}
</style>
