<template>
  <div class="boss">
    <div class="content">
      <div class="top">
        <div class="left">
          <div class="left_img">
            <el-carousel
              :interval="5000"
              arrow="always"
              height="25rem"
              indicator-position="none"
              ref="remarkCaruselUp"
              :initial-index="1"
            >
              <el-carousel-item
                v-for="(item, value) in data.thumbs"
                :key="item"
                :name="value"
              >
                <img class="img" :src="item" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="item">
            <div
              class="demo"
              v-for="(item, value) in data.thumbs"
              :key="value"
              @click="imgClick(value)"
            >
              <img class="img" :src="item" alt="" />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right_content">
            <div class="right_content_top">
              <div class="title">
                {{ data.goodsName }}
              </div>
              <div class="right_content_top_content">
                {{ data.goodsDesc }}
              </div>
              <div class="price">￥{{ data.spe_price }}</div>
            </div>
            <div class="right_content_bottom">
              <div class="classification">
                <div class="classification_left">选择分类：</div>
                <div class="classification_right">
                  <div
                    v-for="(item, value) in data.attrList.field1"
                    @click="setNum(value)"
                    :class="
                      value === num
                        ? 'classification_item_befo classification_item'
                        : 'classification_item'
                    "
                    :key="item.id"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <div class="number">
                <div class="number_left">数量：</div>
                <div class="number_right">
                  <div
                    :class="num1 > 1 ? 'reduce' : 'reduce redAdd'"
                    @click="reduce"
                  >
                    -
                  </div>
                  <div class="num">{{ num1 }}</div>
                  <div class="add" @click="add">+</div>
                </div>
              </div>
              <div class="shopping">
                <div class="shopping_left" @click="buy">立即购买</div>
                <div class="shopping_right" @click="goCart">加入购物车</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div>商品详情</div>
        <div v-html="data.info"></div>
      </div>
      <el-dialog v-model="centerDialogVisible" title="温馨提示" width="30%" center>
    <span
      >您还没有登录，是否前往登录</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onGo">立即前往</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >取消</el-button
        >
      </span>
    </template>
  </el-dialog>
    </div>
  </div>
</template>

<script>
import { reactive, ref, h } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/request/index'
import { ElMessage } from 'element-plus'
export default {
  name: 'detailsS',
  setup () {
    const route = useRoute()
    const store = useRouter()
    //   获取商品详情
    const data = reactive({})
    const param = new URLSearchParams()
    param.append('goodsId', route.query.goodsId)
    if (route.query.flag === 'ly') {
      axios({
        method: 'post',
        url: 'gou/index/goods-detail',
        data: param
      }).then((res) => {
        const resData = res.data.data.detail
        resData.info = resData.info.replaceAll('<img', '<img style="width:70%;height:auto;display:block;margin:0 auto;"')
        for (const item in resData) {
          data[item] = resData[item]
        }
      })
    } else {
      axios({
        method: 'post',
        url: 'mall_xcx/index/goods-detail',
        data: param
      }).then((res) => {
        const resData = res.data.data.detail
        resData.info = resData.info.replaceAll('<img', '<img style="width:70%;height:auto;display:block;margin:0 auto;"')
        for (const item in resData) {
          data[item] = resData[item]
        }
      })
    }
    const centerDialogVisible = ref(false)
    // 底部产品选择部分
    // 选择那种规格
    const num = ref(0)
    const setNum = (v) => {
      num.value = v
    }
    // 定义购买产品数量
    const num1 = ref(1)
    // 减少商品数量
    const reduce = () => {
      if (num1.value > 1) {
        num1.value = num1.value - 1
      } else {
        ElMessage({
          message: h('p', null, [h('span', null, '数量低于范围')])
        })
      }
    }
    // 增加产品数量
    const add = () => {
      num1.value = num1.value + 1
    }
    // 立即购买
    const buy = () => {
      if (window.localStorage.getItem('token')) {
        store.push({ path: 'confiem', query: { goodsId: route.query.goodsId, flag: route.query.flag, number: num1.value } })
      } else {
        centerDialogVisible.value = true
      }
    }
    // 加入购物车
    const goCart = () => {
      if (window.localStorage.getItem('token')) {
        const params = new URLSearchParams()
        params.append('token', window.localStorage.getItem('token'))
        params.append('goodsId', data.id)
        params.append('goodsNum', num1.value)
        if (data.attrList.length > 0) {
          params.append('attrId', data.attrList[num.value])
        }
        params.append('type', 'add')
        axios({
          method: 'post',
          url: 'mall_xcx/cart/add',
          data: params
        }).then((res) => {
          ElMessage(res.data.msg)
        })
      } else {
        centerDialogVisible.value = true
      }
      // store.push({ path: 'shoppingCart', query: { goodsId: data.id } })
    }
    // 定义轮播图展示第n张
    const remarkCaruselUp = ref(0)
    const imgClick = (v) => {
      console.log(v)
      remarkCaruselUp.value.setActiveItem(v)
    }
    const onGo = () => {
      store.push({ path: 'login' })
      centerDialogVisible.value = false
    }

    // 判断是否登录
    return {
      data,
      num,
      num1,
      add,
      reduce,
      buy,
      goCart,
      imgClick,
      remarkCaruselUp,
      centerDialogVisible,
      onGo,
      setNum
    }
  }
}
</script>

<style lang="less" scoped>
.boss {
  background: #e8dfd9;
  padding: 2rem;
  .content {
    width: 70%;
    margin: 0 auto;
    padding: 2rem;
    background: white;
    border-radius: 2rem;
    min-height: 100vh;
    .top {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      .left {
        width: 45%;
        background: #fafafa;
        .left_img {
          width: 80%;
          margin: 0 auto;
          .img {
            width: 100%;
            height: 24rem;
          }
        }
        .item {
          display: flex;
          // justify-content: space-between;
          padding-top: 3rem;
          background: white;
          padding: 0 2rem;
          .demo {
            height: 7rem;
            overflow: hidden;
            border-radius: 1rem;
            margin-right: 0.5rem;
            .img {
              width: 5rem;
              height: 5rem;
            }
          }
        }
      }
      .right {
        width: 58%;
        padding: 0 1rem;
        .right_content {
          .right_content_top {
            .title {
              font-size: 1rem;
              font-weight: bold;
            }
            .right_content_top_content {
              font-size: 0.8rem;
              color: #999;
              margin-top: 1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box; //将对象作为弹性伸缩盒子模型显示。
              -webkit-box-orient: vertical; // 从上到下垂直排列子元素
              -webkit-line-clamp: 2; //显示的行数
            }
            .price {
              color: red;
              margin: 1rem 0;
              font-size: 1.2rem;
              font-weight: bold;
            }
          }
          .right_content_bottom {
            .classification {
              display: flex;
              .classification_left {
                font-family: PingFangSC-Regular;
                font-size: 1rem;
                color: #7f7f7f;
                line-height: 0.8rem;
                // margin-right: 1rem;
                width: 5.5rem;
              }
              .classification_right {
                margin-top: -0.2rem;
                display: flex;
                flex-wrap: wrap;
                .classification_item {
                  margin-bottom: 0.2rem;
                  background-color: rgba(0, 0, 0, 0.06);
                  border: 0.1rem solid transparent;
                  margin-right: 0.5rem;
                  margin-left: 0.5rem;
                  padding: 0.2rem 0.4rem;
                  text-align: center;
                  font-size: 0.4rem;
                  border-radius: 0.1rem;
                }
                .classification_item_befo {
                  border-color: #ff5000;
                  background-color: #fff7f3;
                  color: #ff5000;
                }
              }
            }
            .number {
              display: flex;
              margin-top: 2rem;
              .number_left {
                font-family: PingFangSC-Regular;
                font-size: 1rem;
                color: #7f7f7f;
                line-height: 1.7rem;
                // margin-right: 1rem;
                width: 6.1rem;
              }
              .number_right {
                display: flex;
                .reduce,
                .add {
                  background-color: rgba(0, 0, 0, 0.06);
                  text-align: center;
                  line-height: 2rem;
                  height: 2rem;
                  width: 2rem;
                  font-size: 0.5rem;
                }
                .num {
                  text-align: center;
                  line-height: 2rem;
                  width: 3.75rem;
                  margin: 0 2rem;
                  height: 2rem;
                  background-color: rgba(0, 0, 0, 0.06);
                  font-size: 0.6rem;
                  margin: 0 0.2rem;
                }
                .redAdd {
                  color: #ccc;
                  background-color: rgba(0, 0, 0, 0.06);
                }
              }
            }
            .shopping {
              display: flex;
              margin-top: 2rem;
              width: 30rem;
              color: white;
              .shopping_left {
                background: linear-gradient(
                  90deg,
                  rgb(255, 119, 0),
                  rgb(255, 73, 0)
                );
                box-shadow: rgb(255 119 0 / 20%) rem 9rem 13rem rem;
                outline: 0;
                font-family: PingFang SC;
                border: 0;
                cursor: pointer;
                height: 3rem;
                line-height: 3rem;
                text-align: center;
                padding: 0 3rem;
                border-radius: 25rem 0 0 25rem;
                color: white;
              }
              .shopping_right {
                background: linear-gradient(
                  90deg,
                  rgb(255, 203, 0),
                  rgb(255, 148, 2)
                );
                box-shadow: rgb(255 203 0 / 20%) rem 9rem 13rem rem;
                outline: 0;
                font-family: PingFang SC;
                border: 0;
                cursor: pointer;
                height: 3rem;
                line-height: 3rem;
                text-align: center;
                padding: 0 3rem;
                border-radius: 0 25rem 25rem 0;
                color: white;
              }
            }
          }
        }
      }
    }
    .bottom{
      div:nth-child(1){
        font-size: 3rem;
        font-weight: bold;
        line-height: 16rem;
        text-align: center;
      }
    }
  }
}
</style>
